'use client';

import { assetsService } from '@api-client/index';
import { cleanProjectData } from '@mpx-sdk/helpers/assets';
import { userProfileDisplayAtom } from '@mpx-sdk/shared/atoms';
import { type PublicAsset } from '@mpx-sdk/types';
import AssetDisplay from '@mpx-sdk/ui/components/assets/AssetDisplay';
import { OverlayLoading } from '@mpx-sdk/ui/components/core';
import { useAtomValue } from 'jotai';
import { debounce, isEmpty } from 'lodash';
import { type ReactElement, useEffect, useState } from 'react';

export default function Gallery(): ReactElement | null {
	const [loading, setLoading] = useState<boolean>(true);
	const [projectData, setProjectData] = useState<PublicAsset[]>([]);
	const slugUser = useAtomValue(userProfileDisplayAtom);

	async function getProjectDataFromUser() {
		if (isEmpty(projectData)) {
			setLoading(true);
		}

		if (slugUser?.id) {
			const data = await assetsService.getProjectsByUser(slugUser?.username, 500);

			if (data) {
				const cleanedData = cleanProjectData([...projectData, ...data]);

				setProjectData(cleanedData);
			} else {
				setProjectData([]);
			}
		} else {
			setProjectData([]);
		}

		setLoading(false);
	}

	// Debounce the function to prevent multiple calls
	const debouncedGetProjectDataFromUser = debounce(getProjectDataFromUser, 1500);

	useEffect(() => {
		debouncedGetProjectDataFromUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slugUser]);

	// eslint-disable-next-line no-nested-ternary
	return !isEmpty(projectData) ? (
		<AssetDisplay
			centerTitle
			displayBehavior='publicProfile'
			displaySort={false}
			projectData={projectData}
			title='Remixes'
		/>
	) : loading ? (
		<OverlayLoading />
	) : null;
}
