import { CloseIcon } from '@mpx-sdk/images';
import Env from '@mpx-sdk/shared/configs/env';
import { Card, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import Link from 'next/link';
import { type ReactElement, useEffect } from 'react';

interface NotificationProps {
	id: string;
	title: string;
	message: string;
	createdAt: Timestamp;
	updatedAt: Timestamp;
	metadata?: {
		processingStatus?: 'processing' | 'success' | 'error';
		[key: string]: any;
	};
	isRead: boolean;
	state?: string;
	status?: string;
	type?: string;
}

interface NotificationCardProps {
	closeNotification?: () => void;
	notification: NotificationProps;
	onDelete: () => void;
	onViewed?: () => void;
}

/** A card that displays a notification. */
export default function NotificationCard({
	onViewed,
	onDelete,
	notification,
	closeNotification,
}: NotificationCardProps): ReactElement {
	const now = moment(Date.now());
	const createdAt = moment(Timestamp.fromDate(notification.createdAt.toDate()).toDate());
	const durationAfterNotification = moment.duration(now.diff(createdAt));

	function notificationCardActionsLink() {
		if (notification?.metadata?.processingStatus === 'success') {
			if (notification.type === 'import-model') {
				return `${Env.MPX_WEBSITE}/storage?tab=my-private-imports`;
			}

			if (notification.type === 'export-model') {
				return `${Env.MPX_WEBSITE}/storage?tab=my-private-exports`;
			}

			if (notification.type === 'public-asset' && notification.metadata?.assetId) {
				return `${Env.MPX_WEBSITE}/library/${notification.metadata.assetId}`;
			}
		}

		return null;
	}

	/**
	 * Generate a human-readable message for the duration since the notification was created.
	 * @returns {string} A string representing the duration since the notification was created.
	 */
	const generateDurationMessage = (): string => {
		if (durationAfterNotification.asYears() > 1) {
			return `${Math.floor(durationAfterNotification.asYears())} years ago`;
		}
		if (durationAfterNotification.asMonths() > 1) {
			return `${Math.floor(durationAfterNotification.asMonths())} months ago`;
		}
		if (durationAfterNotification.asDays() > 1) {
			return `${Math.floor(durationAfterNotification.asDays())} days ago`;
		}
		if (durationAfterNotification.asHours() > 1) {
			return `${Math.floor(durationAfterNotification.asHours())} hours ago`;
		}
		if (durationAfterNotification.asMinutes() > 1) {
			return `${Math.floor(durationAfterNotification.asMinutes())} minutes ago`;
		}
		return `${Math.floor(durationAfterNotification.asSeconds())} seconds ago`;
	};

	useEffect(() => {
		if (!document.hidden) {
			onViewed?.();
		}
	}, [onViewed]);

	const link = notificationCardActionsLink();
	const card = (
		<Card
			key={notification.id}
			component={Stack}
			direction='row'
			sx={{ p: 2, position: 'relative', borderRadius: 6 }}
		>
			<span style={{ flexGrow: 1, paddingRight: '22px' }}>
				<Typography fontWeight='bold' gutterBottom>
					{notification.title}
				</Typography>
				<Typography color='text.primary' variant='body2'>
					{notification.message}
				</Typography>
				<Typography color='text.subtext' sx={{ float: 'right', mt: 1 }} variant='caption'>
					{generateDurationMessage()}
				</Typography>
			</span>

			<IconButton
				aria-label='close'
				color='error'
				disableRipple
				onClick={(e) => {
					e.preventDefault();

					onDelete();
				}}
				sx={{ position: 'absolute', top: 0, right: 5 }}
			>
				<CloseIcon fontSize='small' />
			</IconButton>

			{notification?.metadata?.processingStatus === 'processing' && (
				<LinearProgress
					sx={{
						width: '100%',
						position: 'absolute',
						left: 0,
						bottom: 0,
					}}
				/>
			)}

			{notification?.metadata?.processingStatus === 'success' && (
				<LinearProgress
					color='success'
					sx={{
						width: '100%',
						position: 'absolute',
						left: 0,
						bottom: 0,
					}}
					value={100}
					variant='determinate'
				/>
			)}

			{[notification?.metadata?.processingStatus, notification.state, notification.status].includes('error') && (
				<LinearProgress
					color='error'
					sx={{
						width: '100%',
						position: 'absolute',
						left: 0,
						bottom: 0,
					}}
					value={100}
					variant='determinate'
				/>
			)}
		</Card>
	);

	return link ? (
		<Link
			href={link}
			onClick={() => {
				closeNotification?.();
			}}
		>
			{card}
		</Link>
	) : (
		card
	);
}
