import { userService } from '@mpx-sdk/api-client';
import Auth0Helper from '@mpx-sdk/helpers/auth';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { base64ToString } from '@mpx-sdk/shared/utils';
import { MarkEmailUnread } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Container, Link, Stack, Typography } from '@mui/material';
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { GradientTypography } from '../core';

function EmailVerificationRequired() {
	const responsiveView = UIHelper.isResponsiveView();
	const [isSendingEmailVerification, setIsSendingEmailVerification] = useState(false);

	let userId = '';
	let email = '';
	const searchQuery = useSearchParams();
	const state = searchQuery.get('verificationToken');

	if (state) {
		const { userId: userIdFromState, email: emailFromState } = JSON.parse(base64ToString(state));
		userId = userIdFromState;
		email = emailFromState;
	}

	const handleResendEmailVerification = async () => {
		setIsSendingEmailVerification(true);
		try {
			await userService.resendEmailVerification(userId);
			toast.success('Verification email sent.');
		} catch (err) {
			toast.error('There was an error sending email.');
			console.error(err);
		} finally {
			setIsSendingEmailVerification(false);
		}
	};

	const handleVerifyEmailDone = async () => {
		Auth0Helper.login({
			loginHint: email,
			returnTo: '/',
		});
	};

	return (
		<Container sx={{ textAlign: 'center' }}>
			<MarkEmailUnread
				sx={{
					fontSize: '10rem',
					background: 'linear-gradient(91.02deg, #00EAB7 10.41%, #FF9B53 49.23%, #FF268E 89.7%)',
					WebkitBackgroundClip: 'text',
					WebkitTextFillColor: 'transparent',
				}}
			/>
			<GradientTypography variant='h2'>Verify your email</GradientTypography>
			<Typography maxWidth='70%' mt={2} mx='auto' textAlign='center' variant={responsiveView ? 'h5' : 'h6'}>
				We have sent an email to your email address,{' '}
				<Typography color='primary.main' component='span' variant='inherit'>
					{email}
				</Typography>
				.
				<br />
				Please click the link in the email to verify your email address.
			</Typography>
			<br />
			<Stack direction='row' justifyContent='center' spacing={2}>
				<LoadingButton
					loading={isSendingEmailVerification}
					onClick={handleResendEmailVerification}
					variant='contained'
				>
					Resend verification mail
				</LoadingButton>
				<Button
					onClick={handleVerifyEmailDone}
					sx={{ background: (theme) => theme.palette.gradient.main }}
					variant='contained'
				>
					I have verified my email
				</Button>
			</Stack>
			<br />
			{/*
				 eslint-disable-next-line jsx-a11y/anchor-is-valid
			*/}
			<Link component='button' onClick={handleVerifyEmailDone} type='button'>
				Not your email? Login with another account
			</Link>
		</Container>
	);
}

export default EmailVerificationRequired;
