import { subscriptions } from '@mpx-sdk/helpers/assets';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { CloseIcon, NotificationsIcon } from '@mpx-sdk/images';
import { notificationCountAtom, updatePrivateData, userAtom } from '@mpx-sdk/shared/atoms';
import NotificationCard from '@mpx-sdk/ui/components/notifications/NotificationCard';
import { Badge, Box, CircularProgress, IconButton, Menu, Stack, Tooltip, Typography } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { collection, deleteDoc, doc, getFirestore, onSnapshot, orderBy, query, updateDoc } from 'firebase/firestore';
import { useAtomValue, useSetAtom } from 'jotai';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';

interface NotificationCenterProps {
	autoOpenOnNewNotification?: boolean;
	customAnchorEl?: HTMLElement | null;
	emptyCustomAnchorEl?: () => void;
	setBadgeContent?: (count: number) => void;
	showIcon?: boolean;
}

function NotificationCenter({
	autoOpenOnNewNotification = true,
	customAnchorEl,
	emptyCustomAnchorEl,
	setBadgeContent,
	showIcon = true,
}: NotificationCenterProps) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(customAnchorEl ?? null);
	const [cacheStatus, setCacheStatus] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [notifications, setNotifications] = useState<any[]>([]);
	const firstLoadHappened = useRef(false);
	const responsiveView = UIHelper.isResponsiveView();
	const setNotificationCount = useSetAtom(notificationCountAtom);
	const setUpdatePrivateCloudData = useSetAtom(updatePrivateData);
	const user = useAtomValue(userAtom);

	// Prevent auto open on first load
	const db = useMemo(() => getFirestore(), []);

	/// get notification from firestore
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const notificationsDb = useMemo(() => collection(db, `notifications/${user?.auth0Id}/notifications`), [user]);
	const notificationsQuery = useMemo(() => query(notificationsDb, orderBy('updatedAt', 'desc')), [notificationsDb]);

	function markAllAsRead() {
		notifications.forEach((notification) => {
			updateDoc(doc(notificationsDb, notification.id), {
				isRead: true,
			});
		});
	}

	useEffect(() => {
		if (!user) return;

		onAuthStateChanged(getAuth(), (authUser) => {
			if (!authUser) return;

			const unsubscribe = onSnapshot(notificationsQuery, (snapshot) => {
				let showNotification = false;

				// eslint-disable-next-line no-restricted-syntax
				for (const change of snapshot.docChanges()) {
					if (
						change.type === 'added' ||
						(change.type === 'modified' &&
							change.doc.data().isRead !== notifications[change.newIndex]?.isRead)
					) {
						// if autoOpenOnNewNotification is true, open notification center
						if (autoOpenOnNewNotification && firstLoadHappened.current) {
							showNotification = true;
							break;
						}
					}
				}

				if (showNotification && !customAnchorEl) {
					setAnchorEl(document.getElementById('notification-center'));
				}

				const data: any = snapshot.docs.map((doc) => ({
					id: doc.id,
					...doc.data(),
				}));

				// !TODO: Here is where I'll create the updater to update all cloud/private project displays
				// Get all the projects statues and compare it to the cacheStatus to determine whether the page should update or not
				const newCacheStatus: any[] = [];
				data.forEach((notification) => {
					if (notification?.metadata?.processingStatus === 'success') {
						newCacheStatus.push(notification.metadata.processingStatus);
					}
				});

				newCacheStatus.sort();

				if (newCacheStatus?.length > cacheStatus?.length && firstLoadHappened.current) {
					setUpdatePrivateCloudData(true);
				}
				setCacheStatus(newCacheStatus);

				if (!firstLoadHappened.current) {
					firstLoadHappened.current = true;
				}

				setNotifications(data);
				setNotificationCount(data.filter((notification) => !notification?.isRead).length);
				setLoading(false);
			});
			subscriptions.push(unsubscribe);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notificationsQuery, user, autoOpenOnNewNotification]);

	useEffect(() => {
		setBadgeContent?.(notifications.filter((notification) => !notification?.isRead).length);
	}, [notifications, setBadgeContent]);

	useEffect(() => {
		if (customAnchorEl) {
			setAnchorEl(customAnchorEl);
		}
	}, [customAnchorEl]);

	useEffect(() => {
		if (anchorEl) {
			markAllAsRead();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [anchorEl]);

	if (!user) return null;

	return (
		<div>
			{showIcon && (
				<IconButton
					aria-label='Toggle notification center'
					color='default'
					id='notification-center'
					onClick={(event) => {
						if (customAnchorEl) {
							setAnchorEl(customAnchorEl);
						} else {
							setAnchorEl(event.currentTarget);
						}
					}}
				>
					<Badge
						badgeContent={notifications.filter((notification) => !notification?.isRead).length}
						color='primary'
					>
						<NotificationsIcon
							style={{
								fontSize: '2rem',
							}}
						/>
					</Badge>
				</IconButton>
			)}

			<Menu
				anchorEl={anchorEl}
				className='navbar-menu'
				onClose={() => {
					setAnchorEl(null);
					emptyCustomAnchorEl?.();
				}}
				open={Boolean(anchorEl)}
				sx={{
					'.MuiMenu-list': {
						margin: 'auto !important',
					},
				}}
				transformOrigin={{
					horizontal: 'right',
					vertical: responsiveView ? 'top' : 'bottom',
				}}
			>
				<Box
					sx={{
						minWidth: '325px',
						maxWidth: '500px',
						maxHeight: '80%',
						overflow: 'auto',
						p: 2,
					}}
				>
					<Typography textAlign='center'>Notification Center</Typography>

					<Tooltip arrow describeChild title='Close the notification center'>
						<IconButton
							aria-label='Close notification center'
							onClick={() => {
								setAnchorEl(null);
								emptyCustomAnchorEl?.();
							}}
							sx={{
								// Should cover in top right of menu
								position: 'absolute',
								top: '1rem',
								right: '1rem',
								svg: {
									fontSize: '1rem',
									color: 'icons.inactive',
								},
							}}
						>
							<CloseIcon />
						</IconButton>
					</Tooltip>

					<br />

					{!loading && isEmpty(notifications) && (
						<Typography component='div' textAlign='center' variant='caption'>
							You don&apos;t have any notifications yet.
						</Typography>
					)}

					{loading && <CircularProgress />}

					<Stack spacing={2}>
						{notifications.map((notification) => (
							<NotificationCard
								key={notification.id}
								closeNotification={() => {
									setAnchorEl(null);
									emptyCustomAnchorEl?.();
								}}
								notification={notification}
								onDelete={async () => {
									await deleteDoc(doc(notificationsDb, notification.id));
								}}
							/>
						))}
					</Stack>
				</Box>
			</Menu>
		</div>
	);
}

export default NotificationCenter;
