import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { type ReactElement } from 'react';

export default function SearchClearButton(props): ReactElement {
	// Button that says clear that resets the search query to empty
	return (
		<Tooltip arrow describeChild title='Clear search'>
			<IconButton aria-label='Clear search' className='search-clear-button' {...props}>
				<CloseIcon />
			</IconButton>
		</Tooltip>
	);
}
