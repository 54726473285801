import { TextField } from '@mpx-sdk/ui/components/core/TextField';
import { type ReactElement, useState } from 'react';

interface ProfileTextFieldProps {
	label: string;
	/** The short hint displayed in the input before the user enters a value. */
	placeholder: string;
	value?: string;
	/** Maximum length of the text field (if given, will also display character counter) */
	maxLength?: number;
	/** Callback fired when the value is changed. */
	onChange?: (value: string) => void;
	input?: any;
}

/**
 * Profile text field component
 * @returns {ReactElement} Profile text field component
 * @example <caption>Use this over just TextField when you want to pass custom value or functions</caption>
 * <ProfileTextField label="Twitter" placeholder="username" value="AlexJSully" maxLength={15} />
 * // returns <JSX element that has "Twitter" above it and a character counter of 15, editable value of AlexJSully and a placeholder of "username" when empty />
 * @see {@link TextField}
 * @export
 */
export default function ProfileTextField({
	label,
	placeholder,
	maxLength,
	value,
	onChange,
	input,
}: ProfileTextFieldProps): ReactElement {
	/** Currently displayed value */
	const [useValue, setUseValue] = useState(value || '');

	return (
		<>
			{/* Visual text counter */}
			{Number(maxLength) ? (
				<div className='profile-char-count'>{`${useValue?.length} / ${Number(maxLength)}`}</div>
			) : null}

			{/* Input field */}
			<TextField
				fullWidth
				inputProps={{ maxLength: Number(maxLength) }}
				label={label}
				onChange={(e) => {
					setUseValue(e.target.value);

					if (onChange) {
						onChange(e.target.value);
					} else if (input?.onChange) {
						input.onChange(e.target.value);
					}
				}}
				placeholder={placeholder}
				value={useValue}
				{...input}
			/>
		</>
	);
}
