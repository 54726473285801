/* eslint-disable react/prop-types */
import { Chip, Grid } from '@mui/material';
import classNames from 'classnames';
import { type ReactElement, useEffect, useMemo, useState } from 'react';
import { type RefinementListProps, useRefinementList } from 'react-instantsearch';

/**
 * Renders a list of tags that can be used to refine search results.
 * This component is intended to be used with the react-instantsearch-dom `connectRefinementList` function.
 * @returns {ReactElement} A grid of chips representing the tags.
 */
function TagRefinementList(props: RefinementListProps): ReactElement {
	const { items, refine } = useRefinementList(props);
	// Only show a maximum of 6 items
	const maxItems = useMemo(
		() =>
			items
				.filter(
					(item) =>
						![
							'broke',
							'broken',
							'broken-remix',
							'brokenloading',
							'nsfw',
							'qa',
							'qa-test',
							'qa-test-tag',
							'qatest',
						].includes(item.label.toLowerCase()) && item.count > 0,
				)
				.sort((a, b) => {
					if (a.isRefined && !b.isRefined) {
						return -1;
					}
					if (!a.isRefined && b.isRefined) {
						return 1;
					}
					return a.label.localeCompare(b.label);
				})
				.slice(0, 12),
		[items],
	);
	const [itemsToRender, setItemsToRender] = useState(maxItems);

	useEffect(() => {
		setItemsToRender(maxItems);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	return (
		<Grid
			aria-label='Tag Refinement List'
			container
			// Justify content is left when overflow is needed, otherwise it is center
			justifyContent='flex-start'
			spacing={1}
			sx={{
				flexWrap: 'nowrap',
				margin: 'auto 0',
				maxWidth: '100%',
				overflowX: 'auto',
				'.MuiGrid-item': {
					paddingTop: {
						xs: '0.5rem',
						md: 0,
					},
				},
				// Minimal stylized scrollbar
				'&::-webkit-scrollbar': {
					backgroundColor: 'transparent',
					height: '8px',
					width: '8px',
				},
				'&::-webkit-scrollbar-thumb': {
					backgroundColor: 'background.paper',
					borderRadius: '8px',
				},
				'&::-webkit-scrollbar-track': {
					backgroundColor: 'transparent',
				},
			}}
		>
			{itemsToRender.map((item) => (
				<Grid key={`tag-${item.label}`} item sx={{ padding: 0 }}>
					<Chip
						aria-pressed={item.isRefined ? 'true' : 'false'}
						className={classNames('search-filters-tags-showcase search-tags-library', {
							'tag-showcase-active': item.isRefined,
						})}
						clickable
						color={item.isRefined ? 'primary' : 'default'}
						data-project-tag={`showcase-${item.label}`}
						label={item.label}
						onClick={() => {
							refine(item.value);
						}}
						role='button'
						sx={{ fontSize: 16, padding: '18px 3px' }}
						tabIndex={0}
					/>
				</Grid>
			))}
		</Grid>
	);
}

export default TagRefinementList;
