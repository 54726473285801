import { request } from '@mpx-sdk/helpers/api';
import { sanitizeString } from '@mpx-sdk/helpers/input';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { CloseIcon } from '@mpx-sdk/images';
import { flagDialogOpenAtom, flagProjectDataAtom } from '@mpx-sdk/shared/atoms';
import { HelpOutline as HelpOutlineIcon } from '@mui/icons-material';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Tooltip,
} from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import Link from 'next/link';
import { type ReactElement, useMemo } from 'react';
import { toast } from 'react-toastify';

interface FlagDialogProps {
	/** The function to call when the modal is closed */
	onClose?: () => void;
}

/** Dialog to report an asset for violating community guidelines */
export default function FlagDialog({ onClose }: FlagDialogProps): ReactElement {
	const [flagDialogOpen, setFlagDialogOpen] = useAtom(flagDialogOpenAtom);
	const projectData = useAtomValue(flagProjectDataAtom);

	/** The reason the user is flagging the asset */
	const reportReasons = useMemo(
		() => ({
			'Sexual content': {
				tooltip: 'Asset contains sexual content such as nudity, sexual language, or sexual acts.',
			},
			'Violent or repulsive content': {
				tooltip: 'Asset contains content that is violent or graphic.',
			},
			'Hateful or abusive content': {
				tooltip: 'Asset contains or promotes hatred against an individual or group.',
			},
			'Harassment or bullying': {
				tooltip:
					'Asset contains content that threatens individuals or groups with malicious intent or insults.',
			},
			Misinformation: {
				tooltip: 'Asset contains intentional misleading or deceptive information.',
			},
			'Child abuse': {
				tooltip:
					'Asset contains content that includes sexual, predatory, or abusive communications towards minors.',
			},
			Spam: {
				tooltip: 'Asset contains or is content that is massively posted or malicious in intent.',
			},
			'Copyright or plagiarism': {
				tooltip:
					'Asset contains content that does not belong to the owner os the asset and is being used for financial gain.',
			},
		}),
		[],
	);

	/** Send flag data to the server */
	async function sendFlagData() {
		if (projectData?.id) {
			/** Which name="radio-buttons-group" is selected */
			const selectedReason = (
				document.querySelector('input[name="radio-buttons-group"]:checked') as HTMLInputElement
			)?.value;

			if (selectedReason) {
				try {
					await request(`/project/flag/${projectData.id}`, 'POST', {
						reason: sanitizeString(selectedReason),
					});

					DataLayer.triggerMeasurementEvent('flagEvent', {
						assetId: projectData.id,
						assetTitle: projectData.title,
						flagReason: selectedReason,
					});

					toast.success(`${projectData?.title ?? 'Asset'} has been flagged.`);
				} catch (error) {
					console.error(error);
					toast.error(`Error flagging asset.`);
				}
			}
		}
	}

	return (
		<Dialog
			aria-describedby='flag-dialog-description'
			aria-labelledby='flag-dialog-title'
			className='flag-dialog'
			fullWidth
			maxWidth='sm'
			onClose={() => {
				if (onClose) {
					onClose();
				}
				setFlagDialogOpen(false);
			}}
			open={flagDialogOpen}
		>
			<DialogContent>
				<div>
					Report the following asset: {projectData?.title ?? 'Untitled project'}
					<br />
					<FormControl>
						<RadioGroup defaultValue={Object.keys(reportReasons)[0]} name='radio-buttons-group'>
							{Object.keys(reportReasons).map((reason) => (
								<FormControlLabel
									key={`flag-dialog-${reason}`}
									control={<Radio />}
									label={
										<span>
											{reason}
											<Tooltip
												key={`flag-dialog-${reason}-key`}
												arrow
												describeChild
												title={reportReasons[reason].tooltip}
											>
												<HelpOutlineIcon fontSize='small' />
											</Tooltip>
										</span>
									}
									value={reason}
								/>
							))}
						</RadioGroup>
					</FormControl>
					<br />
					<div className='flag-button-container'>
						<Button
							aria-label='Report asset'
							onClick={() => {
								sendFlagData();

								onClose?.();
							}}
							sx={{
								'&:hover': {
									backgroundColor: 'primary.light',
								},
							}}
							variant='contained'
						>
							Report
						</Button>
					</div>
					<p>
						Flagged assets are reviewed by moderators to be determined if they should be removed from the
						Masterpiece X library and whether action should be taken against the creator or this asset. This
						may take time and we appreciate your patience. Thank you for helping to keep Masterpiece X a
						safe and enjoyable place for everyone. If you have questions, please contact us at{' '}
						<Link
							aria-label='Contact Masterpiece X'
							href='https://www.masterpiecex.com/contact'
							rel='noopener noreferrer'
							style={{
								color: '#7FBEF6',
							}}
							target='_blank'
						>
							https://www.masterpiecex.com/contact
						</Link>{' '}
						or on Twitter at{' '}
						<Link
							aria-label='Contact Masterpiece X via Twitter'
							href='https://twitter.com/withMPStudio'
							rel='noopener noreferrer'
							style={{
								color: '#7FBEF6',
							}}
							target='_blank'
						>
							@withMPStudio
						</Link>
						.
					</p>
				</div>
			</DialogContent>

			<DialogActions>
				<Button
					aria-label='close'
					color='primary'
					onClick={() => {
						onClose?.();
						setFlagDialogOpen(false);
					}}
					sx={{
						fontSize: '1rem',
						svg: {
							marginLeft: '0.5rem',
							fontSize: '1rem',
						},
						'&:hover': {
							backgroundColor: 'primary.light',
						},
					}}
					variant='contained'
				>
					Close <CloseIcon />
				</Button>
			</DialogActions>
		</Dialog>
	);
}
