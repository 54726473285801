import { UIHelper } from '@mpx-sdk/helpers/ui';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { type ReactElement } from 'react';

interface MPXLogoProps {
	/** What to do when a tab change button was pressed */
	handleTabChange?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newValue: number) => void;
}

export default function MPXLogo({ handleTabChange }: MPXLogoProps): ReactElement {
	const responsiveView = UIHelper.isResponsiveView();
	const searchParams = useSearchParams();
	const phaseDependentState = searchParams.get('f');

	return (
		<Link
			href='/'
			shallow
			style={{
				color: '#fff',
			}}
		>
			<Stack
				alignItems='center'
				direction='row'
				justifyContent='flex-start'
				spacing={2}
				sx={{
					width: '100%',
					position: 'relative',
				}}
			>
				<Tooltip arrow describeChild title='Return to Masterpiece X home page'>
					<IconButton
						aria-label='Return to Masterpiece X home page'
						className='navbar-logo-container'
						onClick={() => {
							// Redirect to library
							if (!phaseDependentState && handleTabChange) {
								handleTabChange(null, 0);
							}
						}}
						sx={{
							position: 'relative',
							cursor: 'pointer',
						}}
					>
						<Image
							alt='Masterpiece X'
							className='navbar-logo'
							height={45}
							loading='lazy'
							src='/logos/brand_x.svg'
							width={45}
						/>
					</IconButton>
				</Tooltip>

				{responsiveView && (
					<Typography
						sx={{
							display: { xs: 'none', lg: 'initial' },
							fontFamily: 'Circe',
							fontSize: 'clamp(1rem, 1.5rem, 1.5rem)',
							margin: 'auto !important',
							paddingLeft: '0.25rem !important',
							textDecoration: 'none',
							textTransform: 'uppercase',
							whiteSpace: 'nowrap',
							'&::before': {
								content: '""',
								display: 'inline-block',
								height: '100%',
								verticalAlign: 'middle',
							},
						}}
					>
						Masterpiece X
					</Typography>
				)}
			</Stack>
		</Link>
	);
}
