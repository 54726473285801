/* eslint-disable no-nested-ternary */
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { EditIcon } from '@mpx-sdk/images';
import { adminFeaturesAtom, userAtom, userProfileDisplayAtom, userRolesAtom } from '@mpx-sdk/shared/atoms';
import VerifiedAvatar from '@mpx-sdk/ui/components/profile/VerifiedAvatar';
import EditAvatar from '@mpx-sdk/ui/components/profile/edit-profile/EditAvatar';
import {
	Box,
	Button,
	MenuItem,
	Paper,
	Select,
	type SelectChangeEvent,
	Stack,
	type SxProps,
	Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { usePathname, useRouter } from 'next/navigation';
import { type ReactElement, useState } from 'react';

interface ProfilePageSidebarProps {
	/** The style of the component. */
	sx?: SxProps;
	/** Whether this is a public profile or not */
	publicProfile?: boolean;
}

/** The sidebar for the user's profile page. */
export default function ProfilePageSidebar({ sx, publicProfile = false }: ProfilePageSidebarProps): ReactElement {
	const router = useRouter();
	const pathname = usePathname();

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const adminView = useAtomValue(adminFeaturesAtom);
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const publicUserDisplayed = useAtomValue(userProfileDisplayAtom);
	const responsiveView = UIHelper.isResponsiveView();

	/** The navigation links for the sidebar. */
	const navigation = [
		{ title: 'Edit Profile', url: '/profile/edit-profile' },
		{
			title: 'Payment Methods',
			url: '/profile/payment-methods',
			hidden: !currentUserRoles?.inDevelopment || !adminView,
		},
		{
			title: 'Manage Subscriptions',
			url: '/profile/subscriptions',
		},
		{
			title: 'Advanced Settings',
			url: '/profile/advanced-settings',
		},
		{
			title: 'Social Profiles',
			url: '/profile/social-profiles',
		},
	];

	const userDisplay = [
		{
			title: 'Bio',
			value: publicUserDisplayed?.bio,
		},
	];

	const handleDropdownChange = (event: SelectChangeEvent<string>) => {
		const url = event.target.value;
		router.push(url);
	};

	const avatarStyles = {
		backgroundColor: 'background.paper',
		boxShadow: 2,
		height: '130px !important',
		margin: 'auto',
		marginBottom: 2,
		maxHeight: '100%',
		maxWidth: '100%',
		width: '130px !important',
	};

	return responsiveView || publicProfile ? (
		<Paper
			sx={{
				alignContent: 'center',
				backgroundColor: '#000',
				marginTop: '1rem !important',
				width: responsiveView ? '35%' : '100%',
				...sx,
			}}
		>
			<Stack
				alignItems='center'
				justifyContent='center'
				sx={{
					margin: '2rem auto',
					'.MuiBadge-badge': {
						borderRadius: '50%',
						height: '2rem',
						maxHeight: '2rem',
						maxWidth: '2rem',
						svg: {
							fontSize: '1.5rem',
						},
					},
				}}
			>
				{publicProfile ? (
					<VerifiedAvatar publicFacing styles={avatarStyles} user={publicUserDisplayed} />
				) : (
					<EditAvatar displayBadge displayButton={false} sx={avatarStyles} />
				)}
			</Stack>

			<Stack
				alignItems={publicProfile ? 'center' : 'start'}
				justifyContent={publicProfile ? 'center' : 'start'}
				sx={{
					marginBottom: '2rem',
					width: '100%',
				}}
			>
				{!publicProfile &&
					navigation.map(({ title, url, hidden }) => {
						if (hidden) return null;

						const isActive = url.endsWith(pathname);

						return (
							<Link
								key={`link-${title}`}
								aria-label={`Link for ${title}`}
								href={url}
								style={{
									width: '100%',
								}}
							>
								<Box
									sx={{
										backgroundColor: isActive ? 'background.paper' : 'transparent',
										width: '100%',
										'&:hover': {
											backgroundColor: 'background.paper',
										},
									}}
								>
									<Typography
										key={title}
										aria-label={title}
										color={isActive ? 'primary' : 'text.primary'}
										fontWeight={isActive ? 'bold' : 'normal'}
										sx={{
											borderRight: isActive ? '4px solid #00EAB7' : 'none',
											marginBottom: '1rem',
											marginLeft: '10%',
											padding: '0.5rem',
										}}
									>
										{title}
									</Typography>
								</Box>
							</Link>
						);
					})}

				{publicProfile && (
					<Stack
						alignItems='center'
						direction='column'
						justifyContent='center'
						spacing={2}
						sx={{
							maxWidth: '90%',
						}}
					>
						<Stack
							direction='column'
							spacing={0}
							sx={{
								width: '100%',
							}}
						>
							<Typography
								sx={{
									fontWeight: 'bold',
								}}
							>
								{publicUserDisplayed?.displayName}
							</Typography>

							{!isEmpty(publicUserDisplayed?.username) ? (
								<Typography>@{publicUserDisplayed?.username}</Typography>
							) : null}
						</Stack>

						{userDisplay.map(({ title, value }) => {
							if (!value) return null;

							return (
								<Stack
									key={`user-display-${title}-${publicUserDisplayed?.username}`}
									direction='column'
									spacing={0}
									sx={{
										marginBottom: '1rem',
										marginLeft: '10%',
										padding: '0.5rem',
										width: '100%',
									}}
								>
									<Typography
										key={`title-${title}-${publicUserDisplayed?.username}`}
										aria-label={title}
										color='text.primary'
										fontWeight='bold'
									>
										{title}
									</Typography>

									<Typography
										key={`value-${title}-${publicUserDisplayed?.username}`}
										aria-label={title}
										color='text.primary'
										fontWeight='normal'
									>
										{value}
									</Typography>
								</Stack>
							);
						})}

						{!isEmpty(currentUser) && publicUserDisplayed?.username === currentUser?.username && (
							<Link
								aria-label='Edit profile link'
								href='/profile/edit-profile'
								style={{
									width: '100%',
								}}
							>
								<Button
									aria-label='Edit profile'
									className='sav-header-open-button'
									color='primary'
									fullWidth
									sx={{
										backgroundColor: 'primary.main',
										margin: '2em auto auto auto',
										maxWidth: 'max(280px, 80%)',
										'&:hover': {
											backgroundColor: 'primary.light',
										},
									}}
									variant='contained'
								>
									<EditIcon /> Edit Profile
								</Button>
							</Link>
						)}
					</Stack>
				)}
			</Stack>

			<Link
				key='link-feedback'
				aria-label='Get help and feedback'
				href='/feedback'
				style={{
					width: '100%',
				}}
			>
				<Box
					sx={{
						width: '100%',
						'&:hover': {
							backgroundColor: 'background.paper',
						},
					}}
				>
					<Typography
						key='feedback'
						aria-label='Help & Support'
						sx={{
							marginLeft: '10%',
							marginBottom: '1rem',
							padding: '0.5rem',
							color: 'text.disabled',
							'&:hover': {
								color: 'primary.main',
							},
						}}
					>
						Help & Support
					</Typography>
				</Box>
			</Link>
		</Paper>
	) : (
		<Select
			onChange={handleDropdownChange}
			onClose={() => setIsDropdownOpen(false)}
			onOpen={() => setIsDropdownOpen(true)}
			open={isDropdownOpen}
			sx={{
				backgroundColor: 'background.paper',
				borderRadius: '20px',
				marginBottom: '2rem',
				maxWidth: '100%',
				width: '100%',
				svg: {
					color: 'primary.main',
				},
			}}
			value={pathname}
		>
			{navigation.map(({ title, url, hidden }) => {
				if (hidden) return null;

				return (
					<MenuItem key={`dropdown-${title}`} value={url}>
						{title}
					</MenuItem>
				);
			})}
		</Select>
	);
}
