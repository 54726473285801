import { inAppBrowserAtom } from '@mpx-sdk/shared/atoms';
import {
	CheckCircle as CheckCircleIcon,
	CheckCircleOutline as CheckCircleOutlineIcon,
	RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material';
import { Checkbox, MenuItem, Select } from '@mui/material';
import { useAtomValue } from 'jotai';
import { capitalize } from 'lodash';
import { useSearchParams } from 'next/navigation';
import { useEffect, useRef, useState } from 'react';
import { useRefinementList } from 'react-instantsearch';

/** Renders a list of categories that can be used to refine search results. */
export default function CategoryRefinementList(props) {
	const [displayValue, setDisplayValue] = useState('Model');
	const [open, setOpen] = useState(false);
	const clicked = useRef(false);
	const inApp = useAtomValue(inAppBrowserAtom);
	const searchParams = useSearchParams();
	const forceState = searchParams.get('f');

	const { items, refine } = useRefinementList(props);

	const handleCategoryChange = (event) => {
		const { value } = event.target;
		refine(value);

		// Prevent select from closing when clicking on a checkbox
		clicked.current = true;
		setTimeout(() => {
			clicked.current = false;
		}, 250);
	};

	useEffect(() => {
		// If there are no items with isRefined set to true or all items set to true, set displayValue to "All"
		// If there are multiple items with isRefined set to true, set displayValue to "Multiple"
		// If there is only one item with isRefined set to true, set displayValue to that item's label
		const refinedItems = items.filter((item) => item.isRefined);

		if (refinedItems.length === 0 || refinedItems.length === items.length) {
			setDisplayValue('All Categories');
		} else if (refinedItems.length === 1) {
			setDisplayValue(capitalize(refinedItems[0].label));
		} else {
			setDisplayValue('Multiple Categories');
		}
	}, [items]);

	return (
		<Select
			aria-label='Category'
			className='asset-sort-select'
			label='Category'
			onChange={handleCategoryChange}
			onClose={() => {
				if (!clicked.current) {
					setOpen(false);
				}
			}}
			onOpen={() => setOpen(true)}
			open={open}
			renderValue={() => displayValue}
			SelectDisplayProps={{
				style: {
					paddingBottom: 10,
					paddingRight: 0,
					paddingTop: 10,
				},
			}}
			sx={{
				bgcolor: 'background.paper',
				borderRadius: 28,
				color: 'text.primary',
				display: inApp || forceState ? 'none' : 'block',
				'.MuiInputBase-input': {
					maxWidth: '75%',
				},
				svg: {
					color: 'primary.main',
				},
			}}
			value={displayValue.toLowerCase()}
		>
			<MenuItem key='All Categories' sx={{ display: 'none' }} value='all categories' />
			<MenuItem key='Multiple Categories' sx={{ display: 'none' }} value='multiple categories' />

			{items.map((item) => (
				<MenuItem
					key={item.label}
					disabled={item.count === 0}
					selected={item.isRefined}
					sx={{
						'&:hover': {
							svg: {
								color: 'primary.light',
							},
						},
					}}
					value={item.value}
				>
					<Checkbox
						aria-label={item.label}
						checked={item.count > 0 ? item.isRefined : false}
						checkedIcon={<CheckCircleIcon />}
						data-category-checkbox={`${item.label}-checkbox`}
						data-test-category='algolia-category-refinement'
						data-test-id={`${item.label}-checkbox`}
						disabled={item.count === 0}
						icon={item.count > 0 ? <CheckCircleOutlineIcon /> : <RadioButtonUncheckedIcon />}
						sx={{
							// No hover effect on checkbox
							'&:hover': {
								backgroundColor: 'transparent',
							},
						}}
					/>
					{capitalize(item.label)}
				</MenuItem>
			))}
		</Select>
	);
}
