import { userService } from '@mpx-sdk/api-client';
import { type UserProfile } from '@mpx-sdk/types';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

export async function handleGivingRoles(newUser: UserProfile, newRoles: string[]) {
	if (isEmpty(newUser)) {
		toast.error('No user found');
		return;
	}

	try {
		await userService.updateOtherUsers(newUser.id, {
			roles: newRoles.join(',') || '',
		});

		toast.success('Successfully added roles');
	} catch (error) {
		console.error(error);
		toast.error('Failed to add roles');
	}
}
