/* eslint-disable no-nested-ternary */
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import { CollaborateIcon, HomeIcon, LearnIcon, LibraryIcon, RemixAIIcon, StorageIcon } from '@mpx-sdk/images';
import {
	adminFeaturesAtom,
	genAiResponsiveViewAtom,
	inAppBrowserAtom,
	libraryURLParamsAtom,
	singleAssetViewAtom,
	userAtom,
	userHistoryAtom,
	userRolesAtom,
} from '@mpx-sdk/shared/atoms';
import { fadeInTimeout } from '@mpx-sdk/shared/configs';
import { SearchParamKeys } from '@mpx-sdk/types';
import { useDialogService } from '@mpx-sdk/ui/components/core/DialogService';
import MPXLogo from '@mpx-sdk/ui/components/navigation/MPXLogo';
import NavbarAccountIcon from '@mpx-sdk/ui/components/navigation/NavbarAccountIcon';
import NavbarAccountSlots from '@mpx-sdk/ui/components/navigation/NavbarAccountSlots';
import NotificationCenter from '@mpx-sdk/ui/components/notifications/NotificationCenter';
import BuyMoreCredit from '@mpx-sdk/ui/components/purchase/BuyMoreCredit';
import { Menu as MenuIcon, MenuOpen as MenuOpenIcon } from '@mui/icons-material';
import {
	AppBar,
	Box,
	Button,
	Divider,
	Drawer,
	Fade,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	NoSsr,
	Stack,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import Link from 'next/link';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';

export const drawerStyles = {
	overflow: 'auto',
	'.MuiPaper-root,.navbar-drawer': {
		backgroundColor: 'background.offBlack',
		backgroundImage: 'none !important',
		borderRadius: 0,
		height: 'fit-content',
		maxHeight: '100%',
		width: '100%',

		'.MuiList-root': {
			overflowY: 'auto',
		},

		'.MuiStack-root': {
			padding: '3%',
		},

		'.MuiListItem-root,.navbar-drawer-list-item': {
			paddingLeft: 0,
			paddingRight: 0,
			svg: {
				color: 'text.primary',
				fill: 'text.primary',
				path: {
					fill: 'text.primary',
				},
			},

			'.MuiTypography-root': {
				color: 'text.primary',
			},

			'.MuiMenuItem-root': {
				svg: {
					color: 'text.primary',
					fill: 'text.primary',
					path: {
						fill: 'text.primary',
					},
				},
			},

			'&:hover': {
				backgroundColor: 'primary.light',
				'.MuiListItem-root': {
					color: 'primary.contrastText',
				},
				'.MuiTypography-root': {
					color: 'primary.contrastText',
					fontWeight: '800 !important',
				},
				svg: {
					color: 'primary.contrastText',
					fill: 'primary.contrastText',
					path: {
						fill: 'primary.contrastText',
					},
				},
			},
		},
	},
};

export default function MainNavbar() {
	const router = useRouter();
	const pathname = usePathname();

	const searchParams = useSearchParams();
	const phaseDependentState = searchParams.get(SearchParamKeys.Forced);
	const categoryQuery = searchParams.get(SearchParamKeys.Category);

	const [drawerMenu, setDrawerMenu] = useState(false);
	const [inApp] = useAtom(inAppBrowserAtom);
	const [tab, setTab] = useState<number>(-1);
	const adminView = useAtomValue(adminFeaturesAtom);
	const appType = pathname?.startsWith('/generate') ? 'genai' : 'default';
	const currentUser = useAtomValue(userAtom);
	const currentUserRoles = useAtomValue(userRolesAtom);
	const responsiveView = UIHelper.isResponsiveView();
	const setGenAiResponsiveView = useSetAtom(genAiResponsiveViewAtom);
	const setLibraryURLParams = useSetAtom(libraryURLParamsAtom);
	const setSingleAssetView = useSetAtom(singleAssetViewAtom);
	const userHistory = useAtomValue(userHistoryAtom);
	const { showDialog } = useDialogService();

	const pages = useMemo(
		() => [
			{
				icon: <RemixAIIcon />,
				id: 'remix',
				title: 'Generate',
				tooltip: 'Generate game ready 3D assets with just a few words!',
				url: '/generate',
				onClick: () => {
					setGenAiResponsiveView('create-display');
				},
			},
			{
				icon: <LibraryIcon />,
				id: 'home',
				title: 'Library',
				drawerTitle: 'Community Library',
				url: inApp ? `/library?c=model` : `/library`,
			},
			{
				disabled: !currentUser?.email,
				icon: <StorageIcon />,
				id: 'storage',
				title: 'My Storage',
				url: `/storage`,
			},
			{
				disabled: !currentUser?.email,
				hide: !currentUser || !adminView || !currentUserRoles?.inDevelopment,
				icon: <CollaborateIcon />,
				id: 'collaborate',
				isComingSoon: !adminView || !currentUserRoles?.inDevelopment,
				title: 'Collaboration',
				tooltip: 'Create projects with others in real-time',
				url: `/collaborate`,
			},
			{
				icon: <LearnIcon />,
				id: 'learn',
				title: 'Learn',
				url: `/learn`,
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[adminView, currentUser, currentUserRoles, inApp],
	);

	const handleTabChange = (_event: any, value: any) => {
		if (pathname === '/library') {
			setLibraryURLParams({});
		}
		setTab(value);
	};

	useEffect(() => {
		// Check if tab value is valid
		if (tab < 0 || tab >= pages.length) {
			setTab(-1);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab]);

	useEffect(() => {
		if (pathname) {
			const pageIndex = pages.findIndex((page) => page?.url?.includes(pathname));

			// If the page index is valid, set the active tab
			if (pageIndex >= 0 && pageIndex < pages.length) {
				setTab(pageIndex);
			} else {
				setTab(-1);
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router, pages, pathname]);

	if (phaseDependentState && categoryQuery && inApp) {
		return null;
	}

	return (
		<AppBar
			className='navbar-appbar'
			component='header'
			position='static'
			sx={{
				backgroundColor: 'background.default',
				backgroundImage: 'none !important',
				borderRadius: '0px !important',
				boxShadow: 'none !important',
				maxWidth: '100vw',
				zIndex: (theme) => (theme?.zIndex?.drawer || 10000) + 1,
				borderBottom: (theme) => `1px solid ${theme.palette.borders.dark}`,
			}}
		>
			<Toolbar
				className='navbar-container'
				disableGutters
				sx={{
					justifyContent: 'space-between',
					margin: 'min(3%, 10px) min(calc(8% / 4 * 2.5), 45px) !important',
					maxWidth: '100vw',
					padding: 0,
					zIndex: (theme) => (theme?.zIndex?.drawer || 10000) + 1,
				}}
			>
				{!responsiveView && !inApp && (
					<Box sx={{ flexGrow: 0 }}>
						<IconButton aria-label='Toggle navigation menu' onClick={() => setDrawerMenu(!drawerMenu)}>
							{drawerMenu ? <MenuOpenIcon /> : <MenuIcon />}
						</IconButton>

						<Drawer
							anchor='left'
							className='navbar-drawer'
							onClick={() => setDrawerMenu(false)}
							onClose={() => setDrawerMenu(false)}
							open={drawerMenu}
							sx={drawerStyles}
						>
							<Toolbar
								sx={{
									backgroundColor: 'background.dark',
									minHeight: '5rem !important',
								}}
							/>

							{/* Add the pages as options, icon first then text */}
							<List>
								{/* Home button */}
								<ListItem
									key='home-listItem'
									sx={{
										'.MuiTypography-root': {
											fontWeight: tab === -1 ? 'bold !important' : '500',
										},
									}}
								>
									<Link aria-label='Navigate to Home page' href='/'>
										<ListItemButton>
											<ListItemIcon>
												<HomeIcon />
											</ListItemIcon>
											<ListItemText primary='Home' />
										</ListItemButton>
									</Link>
								</ListItem>

								{pages.map((page, index) => {
									if (page.hide || page.disabled) return null;

									return (
										<ListItem
											key={`${page.title}-listItem`}
											className='navbar-drawer-list-item'
											sx={{
												'.MuiTypography-root': {
													fontWeight: tab === index ? 'bold !important' : '500',
												},
											}}
										>
											<Link aria-label={`Navigate to ${page.title} page`} href={page.url} shallow>
												<ListItemButton
													onClick={(e) => {
														page?.onClick?.();

														setSingleAssetView(null);
														handleTabChange(e, index);
													}}
												>
													<ListItemIcon>{page.icon}</ListItemIcon>
													<ListItemText primary={page?.drawerTitle ?? page.title} />
												</ListItemButton>
											</Link>
										</ListItem>
									);
								})}

								{currentUser && appType === 'genai' && (
									<Stack
										alignItems='center'
										direction='column'
										justifyContent='center'
										spacing={2}
										sx={{
											marginBottom: '2.5%',
										}}
									>
										<Divider
											// Increase thickness of divider
											sx={{
												width: '95%',
												borderColor: 'icons.greyed',
												margin: 'auto',
											}}
										/>

										<Button
											aria-label='Get More Credits button'
											className='navbar-signup-button'
											onClick={() => {
												showDialog(BuyMoreCredit.getAsDialog());

												DataLayer.triggerMeasurementEvent('buyEvent', {
													event_name: 'click_get_more_credits',
													user_id: currentUser?.id,
													user_number_of_created_models: userHistory?.length ?? 0,
												});
											}}
											sx={{
												background: (theme) => theme.palette.gradient.main,
												backgroundColor: 'original.main',
												borderRadius: '100px',
												fontSize: 'clamp(0.5rem, 1rem, 1rem)',
												fontWeight: '700',
												letterSpacing: '0.0025em',
												lineHeight: '32px',
												marginRight: '0.5rem',
												paddingLeft: '0.7rem',
												paddingRight: '0.7rem',
												textTransform: 'none',
												whiteSpace: 'nowrap',
												width: '95%',
												'&:hover': {
													background: (theme) => theme.palette.gradient.hover,
												},
											}}
											variant='contained'
										>
											Get More Credits
										</Button>
									</Stack>
								)}
							</List>
						</Drawer>
					</Box>
				)}

				{/* Logo for large screens */}
				<Box sx={{ flexGrow: responsiveView || inApp ? 0 : 1 }}>
					<MPXLogo handleTabChange={handleTabChange} />
				</Box>

				{(responsiveView || inApp) && (
					<NoSsr>
						<Fade in timeout={fadeInTimeout}>
							<Box
								sx={{
									flexGrow: 1,
									display: 'flex',
								}}
							>
								<Grid
									container
									justifyContent='center'
									spacing={{ sm: 3, xs: 0.5 }}
									sx={{
										height: '100%',
										margin: 'auto !important',
									}}
								>
									{pages.map((page, index) => {
										if (!page.hide) {
											const commonProps = {
												'aria-label': `Change to ${page.title} tab`,
												onClick: (e) => {
													page?.onClick?.();

													if (!page?.isComingSoon) {
														setSingleAssetView(null);
														handleTabChange(e, index);
													}
												},
												sx: {
													color:
														// eslint-disable-next-line no-nested-ternary
														tab === index ? 'primary.main' : 'text.primary',
													padding: 1,
													textTransform: 'none',
													'&:hover': {
														color: 'primary.contrastText',
														backgroundColor: 'primary.light',
													},
												},
												'data-tab-id': `navbar-tab-${page.title.toLowerCase().replace(' ', '-')}`,
												disabled: Boolean(page.disabled ?? page?.isComingSoon ?? false),
											};

											return (
												<Grid
													key={page.title}
													className='navbar-tab-icon'
													item
													style={{
														position: 'relative',
													}}
												>
													<Link
														aria-label={`Navigate to ${page.title} page`}
														href={page.url}
														shallow
													>
														<Tooltip
															arrow
															describeChild
															title={
																page?.isComingSoon ? (
																	<Typography
																		sx={{
																			alignItems: 'center',
																			display: 'flex',
																			flexDirection: 'column',
																			fontSize: '1rem',
																			textAlign: 'center',
																		}}
																		variant='caption'
																	>
																		{page.title}

																		{page.isComingSoon && (
																			<>
																				<br />
																				Coming Soon!
																				{page.tooltip && (
																					<>
																						<br />
																						{page.tooltip}
																					</>
																				)}
																			</>
																		)}
																	</Typography>
																) : null
															}
														>
															{responsiveView ? (
																<Button {...commonProps} color='inherit' variant='text'>
																	{page.title}
																</Button>
															) : (
																<IconButton {...commonProps}>{page.icon}</IconButton>
															)}
														</Tooltip>
													</Link>
												</Grid>
											);
										}

										return null;
									})}
								</Grid>
							</Box>
						</Fade>
					</NoSsr>
				)}

				<Box>
					<Stack alignItems='center' direction='row' justifyContent='flex-end' spacing={1}>
						<NavbarAccountSlots />

						<NotificationCenter autoOpenOnNewNotification={false} />

						<NavbarAccountIcon />
					</Stack>
				</Box>
			</Toolbar>
		</AppBar>
	);
}
