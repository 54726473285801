import { type PublicUserProfile } from '@mpx-sdk/types';
import { isEmpty } from 'lodash';
import { request } from '../api';

export async function updateCurrentUser(userData: Partial<MPS.User>): Promise<MPS.User | null> {
	if (!isEmpty(userData)) {
		const userUpdated = await request(`/me`, 'patch', userData);

		return userUpdated?.data;
	}

	return null;
}

export async function updateUserAvatar(user, avatarData: FormData): Promise<PublicUserProfile | null> {
	if (!user || !avatarData) {
		return null;
	}

	await request(`/me/avatar`, 'POST', avatarData, {
		'Content-Type': 'multipart/form-data',
	});

	const userData = await request('/me', 'GET');

	return userData?.data;
}
