import { userProfileDisplayAtom } from '@mpx-sdk/shared/atoms';
import EditText from '@mpx-sdk/ui/components/single-asset-view/components/EditText';
import { Stack, Typography } from '@mui/material';
import { useAtomValue } from 'jotai';
import { type ReactElement } from 'react';

export default function EditDetails(): ReactElement | null {
	const slugUser = useAtomValue(userProfileDisplayAtom);

	// Edit the bio, but also have a disabled option to edit displayName

	return slugUser ? (
		<Stack direction='column' spacing={2}>
			<Typography>
				Display name: {slugUser.displayName}{' '}
				<span
					style={{
						// this is subtext
						fontSize: '0.8rem',
					}}
				>
					(editing display name coming soon)
				</span>
			</Typography>

			<Stack alignItems='center' direction='row' justifyContent='center' spacing={2}>
				<Typography>Description: </Typography>{' '}
				<EditText enableAdmin={false} maxChar={160} projectData={slugUser} type='bio' />
			</Stack>
		</Stack>
	) : null;
}
