'use client';

import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { subscriptionAtom, userAtom } from '@mpx-sdk/shared/atoms';
import { CookieNames } from '@mpx-sdk/shared/configs';
import Env from '@mpx-sdk/shared/configs/env';
import { setCookie } from 'cookies-next';
import { useAtomValue } from 'jotai';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';

export default function AnalyticsWrapper() {
	const currentUser = useAtomValue(userAtom);
	const subscription = useAtomValue(subscriptionAtom);
	const searchParams = useSearchParams();

	const action = searchParams.get('action');
	const method = searchParams.get('method');
	const pathname = usePathname();
	const router = useRouter();

	useEffect(() => {
		DataLayer.init({
			user_id: currentUser?.auth0Id,
		});

		if (currentUser) {
			// set 1st party cookie to track user in GTM
			setCookie(CookieNames.USER_ID, currentUser.auth0Id, { path: '/', domain: Env.COOKIE_DOMAIN });
			DataLayer.setVariable('user_id', currentUser.auth0Id);
		}

		if (subscription) {
			// Update the analytics data layer with the subscription ID
			let planId = 'free';
			if (subscription && subscription.plan_id) {
				planId = subscription.plan_id;
			}
			DataLayer.setVariable('plan', planId);
		}
		try {
			if (method && action) {
				DataLayer.triggerMeasurementEvent('authEvent', {
					event_name: action,
					login_method: method,
				});
				console.log(pathname);
				// Remove both params from query string but keep the rest
				const url = new URL(pathname, window.location.origin);
				url.search = searchParams.toString();

				url.searchParams.delete('action');
				url.searchParams.delete('method');

				router.replace(url.toString());
			}
		} catch (err) {
			console.log(err);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser, action, method, subscription]);

	return null;
}
