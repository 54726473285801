import { Box, Stack, Typography } from '@mui/material';
import Link from 'next/link';

interface StatusCodeProps {
	code?: number;

	helpfulLinks?: boolean;
	customMessage?: string;
}

export const statusCodeDisplay = {
	401: 'Unauthorized access',
	403: 'Forbidden',
	404: 'Page not found',
	500: 'Internal server error',
};

export default function StatusCode({ code = 404, helpfulLinks = true, customMessage }: StatusCodeProps) {
	return (
		<Box sx={{ bgcolor: 'background.default', height: '100%', flex: 1 }}>
			<Stack alignItems='center' height='70%' justifyContent='center' sx={{ color: 'text.primary' }}>
				<Typography
					fontWeight='bold'
					sx={{
						backgroundImage: 'linear-gradient(29deg, #FF9B53 15.23%, #FF268E 89.7%)',
						WebkitBackgroundClip: 'text',
						color: 'transparent',
					}}
					variant='h1'
				>
					{code}
				</Typography>
				<Typography variant='h3'>{customMessage ?? statusCodeDisplay[code]}</Typography>

				{helpfulLinks && (
					<>
						<br />
						<Typography variant='h6'>Here are some helpful links</Typography>
						<br />
						<Stack>
							{[
								{
									href: `/library`,
									text: 'Masterpiece X Library',
								},
								{
									href: `/generate`,
									text: 'Masterpiece X - Generate',
								},
							].map((link) => (
								<Link key={link.href.slice(1)} href={link.href}>
									{link.text}
								</Link>
							))}
						</Stack>
					</>
				)}
			</Stack>
		</Box>
	);
}
