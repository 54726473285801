/* eslint-disable no-nested-ternary */
import { rolesService } from '@api-client/index';
import Auth0Helper from '@mpx-sdk/helpers/auth';
import firebase from '@mpx-sdk/helpers/firebase';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { UIHelper } from '@mpx-sdk/helpers/ui';
import {
	BookmarkIcon,
	CreditsIconUnstyledIcon,
	LoginIcon,
	LogoXIcon,
	LogoutIcon,
	ProfileIcon,
	SettingsIcon,
} from '@mpx-sdk/images';
import {
	adminFeaturesAtom,
	inAppBrowserAtom,
	inAppVersionAtom,
	userAtom,
	userHistoryAtom,
	userRolesAtom,
} from '@mpx-sdk/shared/atoms';
import { VERSION } from '@mpx-sdk/shared/configs';
import Env from '@mpx-sdk/shared/configs/env';
import { urls } from '@mpx-sdk/shared/configs/urls';
import { useDialogService } from '@mpx-sdk/ui/components/core/DialogService';
import { drawerStyles } from '@mpx-sdk/ui/components/navigation/MainNavbar';
import VerifiedAvatar from '@mpx-sdk/ui/components/profile/VerifiedAvatar';
import BuyMoreCredit from '@mpx-sdk/ui/components/purchase/BuyMoreCredit';
import CreditsLeft from '@mpx-sdk/ui/components/purchase/CreditsLeft';
import { AdminPanelSettings as AdminPanelSettingsIcon, BugReport as BugReportIcon } from '@mui/icons-material';
import {
	Avatar,
	Box,
	Button,
	Divider,
	Drawer,
	IconButton,
	List,
	ListItemIcon,
	Menu,
	MenuItem,
	Stack,
	Switch,
	Toolbar,
	Tooltip,
	Typography,
} from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { intersection, isEmpty } from 'lodash';
import { signIn } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { type ReactElement, useEffect, useMemo, useState } from 'react';

interface NavbarAccountIconProps {
	/** Whether the icon should display phase-dependent options. */
	phaseDependent?: boolean;
	/** Whether the icon should be disabled. */
	disabled?: boolean;
}

/** A button that displays the user's profile image and links to various account settings and pages. */
export default function NavbarAccountIcon({
	phaseDependent = false,
	disabled = false,
}: NavbarAccountIconProps): ReactElement | null {
	const [adminView, setAdminView] = useAtom(adminFeaturesAtom);
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const [currentUser, setCurrentUser] = useAtom(userAtom);
	const [currentUserRoles, setCurrentUserRoles] = useAtom(userRolesAtom);
	const [drawerMenu, setDrawerMenu] = useState(false);
	const [permissionText, setPermissionText] = useState<string | null>(null);
	const [profileDisplayName, setProfileDisplayName] = useState('Guest');
	const inApp = useAtomValue(inAppBrowserAtom);
	const inAppVersion = useAtomValue(inAppVersionAtom);
	const responsiveView = UIHelper.isResponsiveView();
	const router = useRouter();
	const userHistory = useAtomValue(userHistoryAtom);
	const { showDialog } = useDialogService();

	async function getUserRoles() {
		if (currentUser?.id && isEmpty(currentUserRoles)) {
			const roles: any = await rolesService.getMyRoles(currentUser.id);

			if (roles) {
				setCurrentUser({
					...currentUser,
					roles,
				});
				setCurrentUserRoles(roles);

				// Adjust admin view:
				const allowedRoles = ['admin', 'dev', 'marketing', 'tester', 'beta'];

				// Check in roles and see if any of the `allowedRoles` booleans are true
				setAdminView(intersection(roles.roles, allowedRoles).length > 0);
			}
		}
	}

	function getPermissionsText(currentUserRoles: any): string | null {
		if (!currentUserRoles) {
			return null;
		}

		if (currentUserRoles?.roles?.includes('admin')) {
			return 'Admin Features';
		}
		if (currentUserRoles?.roles?.includes('dev')) {
			return 'Developer Features';
		}
		if (currentUserRoles?.roles?.includes('marketing')) {
			return 'Marketing Features';
		}
		if (currentUserRoles?.roles?.includes('beta')) {
			return 'Beta Features';
		}
		if (currentUserRoles?.labs) {
			return 'Labs Features';
		}

		return null;
	}

	const settings = useMemo(
		() => [
			{
				title: 'Admin',
				icon: <AdminPanelSettingsIcon />,
				href: `${Env.MPX_WEBSITE}/admin`,
				hide: !currentUserRoles?.library && !currentUserRoles?.giveRoles,
			},
			{
				title: 'Get More Credits',
				icon: <CreditsIconUnstyledIcon />,
				onClick: () => {
					showDialog(BuyMoreCredit.getAsDialog());

					DataLayer.triggerMeasurementEvent('buyEvent', {
						event_name: 'click_get_more_credits',
						user_id: currentUser?.id,
						user_number_of_created_models: userHistory?.length ?? 0,
					});
				},
			},
			{
				title: 'My Profile',
				icon: <ProfileIcon />,
				href: currentUser?.username
					? `${Env.MPX_WEBSITE}/user/${currentUser?.username}`
					: `${Env.MPX_WEBSITE}/profile`,
				hide: !currentUser?.email,
			},
			{
				title: 'Settings',
				icon: <SettingsIcon />,
				href: `${Env.MPX_WEBSITE}/profile`,
				hide: !currentUser?.email,
			},
			{
				title: 'Feedback & Support',
				icon: <BugReportIcon />,
				href: urls.routes.feedback,
			},
			{
				title: 'Bookmarks',
				icon: <BookmarkIcon />,
				href: `/storage?tab=bookmarks`,
			},
			{
				title: 'Download MetaQuest App',
				icon: <LogoXIcon />,
				href: urls.app.oculus,
				hide: inApp,
				newTab: true,
			},
			{
				title: currentUser?.email ? 'Sign Out' : 'Login',
				icon: currentUser?.email ? <LogoutIcon /> : <LoginIcon />,
				onClick: async () => {
					if (currentUser?.email) {
						await firebase.logout();
						Auth0Helper.logout();
					} else {
						Auth0Helper.login();
					}
				},
				id: 'navbar-logout-button',
				hide: inApp && (!currentUser || !adminView || !currentUserRoles?.inDevelopment),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentUserRoles, currentUser, inApp, responsiveView, userHistory, adminView],
	);

	const styles = useMemo(
		() => ({
			avatar: {
				height: '45px',
				width: '45px',
			},
			menu: {
				borderRadius: '28px !important',
				minWidth: '365px',
				overflow: 'auto',
				marginTop: responsiveView ? '45px' : '2.5%',
			},
			menuItem: {
				color: 'text.primary',
				'&:hover': {
					backgroundColor: 'primary.main',
					color: 'primary.contrastText',
					svg: {
						color: 'primary.contrastText',
						fill: 'primary.contrastText',
						path: {
							fill: 'primary.contrastText',
						},
					},
				},
			},
			button: {
				backgroundColor: 'original.main',
				'&:hover': {
					backgroundColor: 'original.light',
				},
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[responsiveView],
	);

	function displayMenuContent() {
		return (
			<Box>
				<Stack alignItems='center' direction='row' justifyContent='space-between' spacing={1}>
					<Stack
						alignItems='center'
						direction='row'
						justifyContent='flex-start'
						spacing={2}
						sx={{
							padding: '0.5% !important',
						}}
					>
						<Avatar
							alt={`${profileDisplayName}'s avatar`}
							imgProps={{
								loading: 'lazy',
							}}
							src={currentUser?.photoUrl ?? `${currentUser?.firstName} ${currentUser?.lastName}`}
						/>
						<Typography className='navbar-menu-title'>{profileDisplayName}</Typography>
					</Stack>

					{!responsiveView || inApp ? <CreditsLeft /> : null}
				</Stack>

				{!responsiveView && (
					<Divider
						// Increase thickness of divider
						sx={{
							width: '95%',
							borderColor: 'icons.greyed',
							margin: 'auto',
						}}
					/>
				)}

				{permissionText && (
					<MenuItem
						className='navbar-menu-item'
						onClick={() => {
							setAdminView(!adminView);
						}}
						sx={{
							...styles.menuItem,

							svg: {
								fill: (theme) =>
									responsiveView ? theme.palette.icons.greyed : theme.palette.primary.contrastText,
							},
							justifyContent: 'space-between !important',
							'&:hover': {
								backgroundColor: 'transparent',
							},
						}}
					>
						<Typography sx={{ py: 2 }} textAlign='center'>
							{permissionText}
						</Typography>
						<ListItemIcon
							sx={{
								justifyContent: 'center',
							}}
						>
							<Switch checked={adminView} color='primary' />
						</ListItemIcon>
					</MenuItem>
				)}

				{settings.map((setting) => {
					if (!setting.hide) {
						const menuItem = (
							<MenuItem
								key={setting.title}
								className='navbar-menu-item'
								id={setting?.id ?? `${setting?.title}-navbar-item`}
								onClick={async () => {
									await setting?.onClick?.();
									setAnchorElUser(null);
									setDrawerMenu(false);
								}}
								sx={styles.menuItem}
							>
								<ListItemIcon
									sx={{
										justifyContent: 'center',
									}}
								>
									{setting.icon}
								</ListItemIcon>
								<Typography sx={{ py: 2 }} textAlign='center'>
									{setting.title}
								</Typography>
							</MenuItem>
						);

						return setting.href ? (
							<Link
								key={`${setting.title}-navbar-item`}
								aria-label={`Link to ${setting.title}`}
								href={setting.href}
								shallow
								{...(setting.newTab
									? {
											target: '_blank',
											rel: 'noopener noreferrer',
										}
									: {})}
							>
								{menuItem}
							</Link>
						) : (
							menuItem
						);
					}

					return null;
				})}

				{adminView && (
					<Box
						sx={{
							marginTop: '0.5rem',
						}}
					>
						<Divider
							// Increase thickness of divider
							sx={{
								width: '95%',
								borderColor: 'icons.greyed',
								margin: 'auto',
							}}
						/>

						<Typography
							className='navbar-menu-item'
							sx={{
								color: 'text.disabled',
								padding: '1rem',
							}}
						>
							Web version: {VERSION.MPX_WEB} <br />
							{currentUserRoles?.inDevelopment && (
								<>
									API & db version: {VERSION.API_DB}
									<br /> Firebase Functions version: {VERSION.FIREBASE_FUNCTIONS} <br />
								</>
							)}
							{inAppVersion && (
								<>
									<br />
									In-app version: {inAppVersion}
								</>
							)}
						</Typography>
					</Box>
				)}
			</Box>
		);
	}

	useEffect(() => {
		let name = 'Guest';

		if (currentUser?.firstName && currentUser?.lastName && currentUser?.useName) {
			name = `${currentUser.firstName} ${currentUser.lastName}`;
		} else if (currentUser?.username) {
			name = currentUser.username;
		} else if (currentUser?.id) {
			name = String(currentUser?.id);
		}

		if (name !== profileDisplayName) {
			setProfileDisplayName(name);
		}

		setPermissionText(getPermissionsText(currentUserRoles));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		currentUser?.firstName,
		currentUser?.id,
		currentUser?.lastName,
		currentUser?.useName,
		currentUser?.username,
		currentUserRoles,
	]);

	useEffect(() => {
		if (currentUser?.id) {
			getUserRoles();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentUser?.id]);

	return currentUser ? (
		<Box sx={{ flexGrow: 0, display: { sm: 'flex' } }}>
			<Tooltip arrow describeChild title={!phaseDependent ? 'Open settings' : ''}>
				<IconButton
					aria-label='Open settings'
					data-avatar-icon-id='navbar-avatar-button'
					disabled={disabled}
					disableRipple
					onClick={(event) => {
						if (drawerMenu) {
							setAnchorElUser(null);
							setDrawerMenu(false);
						} else {
							setAnchorElUser(event.currentTarget || null);
							setDrawerMenu(true);
						}
					}}
					sx={{ p: 0, maxWidth: '325px' }}
				>
					<VerifiedAvatar styles={styles.avatar} user={currentUser} />
				</IconButton>
			</Tooltip>

			{/* Menu for large screens */}
			{responsiveView && (
				<Menu
					anchorEl={anchorElUser}
					anchorOrigin={{
						horizontal: 'right',
						vertical: responsiveView ? 'top' : 'bottom',
					}}
					className='navbar-menu'
					disableScrollLock
					id='menu-appbar'
					keepMounted
					onClose={() => {
						setAnchorElUser(null);
						setDrawerMenu(false);
					}}
					open={Boolean(anchorElUser)}
					sx={styles.menu}
					transformOrigin={{
						horizontal: 'right',
						vertical: responsiveView ? 'top' : 'bottom',
					}}
				>
					{displayMenuContent()}
				</Menu>
			)}

			{!responsiveView && (
				<Drawer
					anchor='right'
					className='navbar-drawer'
					onClose={() => {
						setAnchorElUser(null);
						setDrawerMenu(false);
					}}
					open={drawerMenu}
					sx={drawerStyles}
				>
					<Toolbar
						sx={{
							backgroundColor: 'background.dark',
							minHeight: '5rem !important',
						}}
					/>

					{/* Add the pages as options, icon first then text */}
					<List>{displayMenuContent()}</List>
				</Drawer>
			)}
		</Box>
	) : (
		<Stack direction='row' spacing={2}>
			<Button
				aria-label={inApp ? 'Link Account button' : 'Login button'}
				className='navbar-signup-button'
				data-cypress='login'
				onClick={async () => {
					if (inApp) {
						router.push(urls.routes.deviceLogin);
					} else {
						await signIn('auth0');
					}
				}}
				sx={{
					...styles.button,
					background: (theme) => (inApp ? theme.palette.gradient.main : 'none'),
					color: inApp ? 'initial' : 'text.primary',
					borderRadius: '100px',
					fontSize: 'clamp(0.5rem, 1rem, 1rem)',
					fontWeight: '700',
					letterSpacing: '0.0025em',
					lineHeight: '32px',
					paddingLeft: '1.25rem',
					paddingRight: '1.25rem',
					textTransform: 'none',
					whiteSpace: 'nowrap',
					'&:hover': {
						background: (theme) => theme.palette.gradient.hover,
						color: inApp ? 'initial' : 'primary.contrastText',
					},
				}}
				variant='contained'
			>
				{inApp ? 'Link Account' : 'Login'}
			</Button>

			{!inApp && (
				<Button
					aria-label='Sign Up button'
					className='navbar-signup-button'
					data-cypress='signup'
					data-testid='navbar-signup-button'
					onClick={async () => {
						await signIn(
							'auth0',
							{},
							{
								screen_hint: 'signup',
							},
						);
					}}
					sx={{
						...styles.button,
						background: (theme) => theme.palette.gradient.main,
						borderRadius: '100px',
						fontSize: 'clamp(0.5rem, 1rem, 1rem)',
						fontWeight: '700',
						letterSpacing: '0.0025em',
						lineHeight: '32px',
						paddingLeft: '1.25rem',
						paddingRight: '1.25rem',
						textTransform: 'none',
						whiteSpace: 'nowrap',
						'&:hover': {
							background: (theme) => theme.palette.gradient.hover,
						},
					}}
					variant='contained'
				>
					Sign Up
				</Button>
			)}
		</Stack>
	);
}
