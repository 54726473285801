'use client';

import { userService } from '@api-client/index';
import { cleanProjectData } from '@mpx-sdk/helpers/assets';
import { userProfileDisplayAtom } from '@mpx-sdk/shared/atoms';
import { type PublicAsset } from '@mpx-sdk/types';
import AssetDisplay from '@mpx-sdk/ui/components/assets/AssetDisplay';
import { useAtomValue } from 'jotai';
import { debounce, isEmpty } from 'lodash';
import { type ReactElement, useEffect, useState } from 'react';

export default function Bookmarks(): ReactElement | null {
	const [projectData, setProjectData] = useState<Array<PublicAsset>>([]);
	const slugUser = useAtomValue(userProfileDisplayAtom);

	async function getBookmarkDataFromUser() {
		if (slugUser) {
			const data = await userService.getMyBookmarks();

			if (data) {
				const cleanedData = cleanProjectData([...projectData, ...data]);

				setProjectData(cleanedData);
			} else {
				setProjectData([]);
			}
		} else {
			setProjectData([]);
		}
	}

	// Debounce the function to prevent multiple calls
	const debouncedGetBookmarkDataFromUser = debounce(getBookmarkDataFromUser, 1500);

	useEffect(() => {
		debouncedGetBookmarkDataFromUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [slugUser]);

	return !isEmpty(projectData) ? (
		<AssetDisplay
			centerTitle
			displayBehavior='publicProfile'
			displaySort={false}
			projectData={projectData}
			title='Bookmarks'
		/>
	) : null;
}
