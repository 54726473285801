'use client';

import { sanitizeString } from '@mpx-sdk/helpers/input';
import { updateCurrentUser } from '@mpx-sdk/helpers/users';
import { ArtStationLogoIcon, SketchFabLogoIcon } from '@mpx-sdk/images';
import { savEditMode, userAtom, userProfileDisplayAtom } from '@mpx-sdk/shared/atoms';
import VerifiedAvatar from '@mpx-sdk/ui/components/profile/VerifiedAvatar';
import EditProfile from '@mpx-sdk/ui/components/profile/display-container/EditProfile';
import EditText from '@mpx-sdk/ui/components/single-asset-view/components/EditText';
import { Facebook, GitHub, Instagram, Language, LinkedIn, Twitter, YouTube } from '@mui/icons-material';
import { Avatar, Button, Grid, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { isEmpty } from 'lodash';
import Link from 'next/link';
import { type ReactElement, useState } from 'react';

/** Component that displays a user's details */
export default function Display(): ReactElement | null {
	const [editMode, setEditMode] = useAtom(savEditMode);
	const [openEditProfile, setOpenEditProfile] = useState(false);
	const [slugUser, setSlugUser] = useAtom(userProfileDisplayAtom);
	const currentUser = useAtomValue(userAtom);

	const iconStyles = {
		color: 'icons.inactive',
	};

	const socialLinks = [
		{
			name: 'ArtStation',
			icon: <ArtStationLogoIcon sx={iconStyles} />,
			link: slugUser?.artstation ? `https://www.artstation.com/${slugUser.artstation}` : null,
		},
		{
			name: 'Facebook',
			icon: <Facebook sx={iconStyles} />,
			link: slugUser?.facebook ? `https://www.facebook.com/${slugUser.facebook}` : null,
		},
		{
			name: 'GitHub',
			icon: <GitHub sx={iconStyles} />,
			link: slugUser?.github ? `https://github.com/${slugUser.github}` : null,
		},
		{
			name: 'Instagram',
			icon: <Instagram sx={iconStyles} />,
			link: slugUser?.instagram ? `https://www.instagram.com/${slugUser.instagram}` : null,
		},
		{
			name: 'LinkedIn',
			icon: <LinkedIn sx={iconStyles} />,
			link: slugUser?.linkedin ? `https://www.linkedin.com/in/${slugUser.linkedin}` : null,
		},
		{
			name: 'Sketchfab',
			icon: <SketchFabLogoIcon sx={iconStyles} />,
			link: slugUser?.sketchfab ? `https://sketchfab.com/${slugUser.sketchfab}` : null,
		},
		{
			name: 'Twitter',
			icon: <Twitter sx={iconStyles} />,
			link: slugUser?.twitter ? `https://twitter.com/${slugUser.twitter}` : null,
		},
		{
			name: 'YouTube',
			icon: <YouTube sx={iconStyles} />,
			link: slugUser?.youtube ? `https://www.youtube.com/channel/${slugUser.youtube}` : null,
		},
		{
			name: 'Website',
			icon: <Language sx={iconStyles} />,
			link: slugUser?.website ?? null,
		},
	];

	async function handleEdit() {
		// data-modal-project-id={`modal-project-${projectData?.id}`}
		// Get that value but the projectData.id should be the id of the slug user
		const newDescription = sanitizeString(
			(
				document?.querySelector(
					`[data-modal-project-id="modal-project-${slugUser?.id}"] textarea`,
				) as HTMLInputElement
			)?.value,
		)?.substring(0, 160);

		if (newDescription && !isEmpty(newDescription) && !isEmpty(currentUser) && currentUser?.id === slugUser?.id) {
			await updateCurrentUser({ bio: newDescription });

			setSlugUser({ ...slugUser, bio: newDescription });
		}

		setEditMode(false);
	}

	return slugUser ? (
		<Grid container spacing={3}>
			<Grid item xs={3}>
				<Stack alignItems='flex-start' direction='column' justifyContent='center' spacing={1}>
					<VerifiedAvatar publicFacing user={slugUser}>
						<Avatar
							alt={`${slugUser.displayName ?? 'User'}'s avatar`}
							imgProps={{
								loading: 'lazy',
							}}
							src={slugUser.photoUrl}
							sx={{
								width: '80%',
								height: '80%',
								maxWidth: 180,
								maxHeight: 180,
								margin: 'auto',
							}}
						/>
					</VerifiedAvatar>
					<Grid alignItems='center' container direction='row' justifyContent='center'>
						{socialLinks.map((link) => {
							if (!link.link) return null;

							return (
								<Link
									key={`social-link-${link.name}`}
									href={link.link}
									rel='noopener noreferrer'
									target='_blank'
								>
									<Grid item>
										<IconButton aria-label={link.name}>{link.icon}</IconButton>
									</Grid>
								</Link>
							);
						})}
					</Grid>
				</Stack>
			</Grid>

			<Grid item xs={9}>
				<Stack alignItems='flex-start' direction='column' justifyContent='center' spacing={2}>
					{/* Profile counts */}
					<Grid
						alignItems='center'
						container
						direction='row'
						justifyContent={{
							xs: 'center',
							sm: 'flex-start',
						}}
						spacing={3}
					>
						{/* Follower Count */}
						{/* <Grid item>
							<Stack alignItems='center' direction='column' justifyContent='center'>
								<Typography variant='h6'>{slugUser?.followerCount}</Typography>
								<Typography variant='subtitle1'>Followers</Typography>
							</Stack>
						</Grid> */}

						{/* Following Count */}
						{/* <Grid item>
							<Stack alignItems='center' direction='column' justifyContent='center'>
								<Typography variant='h6'>{slugUser?.followingCount}</Typography>
								<Typography variant='subtitle1'>Following</Typography>
							</Stack>
						</Grid> */}

						{/* Remix Count */}
						<Grid item>
							<Stack alignItems='center' direction='column' justifyContent='center'>
								<Typography variant='h6'>{slugUser?.remixCount}</Typography>
								<Typography variant='subtitle1'>Remixes</Typography>
							</Stack>
						</Grid>
					</Grid>

					{editMode && currentUser?.id === slugUser?.id ? (
						<EditText enableAdmin={false} maxChar={160} projectData={slugUser} type='bio' />
					) : (
						<Typography
							sx={{
								width: '100%',
							}}
							variant='body1'
						>
							{slugUser.bio}
						</Typography>
					)}
				</Stack>
			</Grid>

			{editMode && currentUser && currentUser?.id === slugUser?.id && (
				<Grid alignItems='center' container direction='row' item justifyContent='center' spacing={2} xs={12}>
					<Grid
						item
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						xs={5}
					>
						<Tooltip arrow describeChild title='Open other options to edit profile page'>
							<Button
								aria-label='Open other options to edit profile page'
								className='sav-header-open-button'
								fullWidth
								onClick={() => setOpenEditProfile(true)}
								sx={{
									maxWidth: '280px',
									margin: 'auto',
								}}
								variant='contained'
							>
								Other options
							</Button>
						</Tooltip>

						<EditProfile
							onClose={() => {
								setOpenEditProfile(false);
							}}
							open={openEditProfile}
						/>
					</Grid>

					<Grid
						item
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
						xs={5}
					>
						<Tooltip arrow describeChild title='Save changes and update profile'>
							<Button
								area-label='Save changes and update profile'
								className='sav-header-open-button'
								color='primary'
								fullWidth
								onClick={() => handleEdit()}
								sx={{
									backgroundColor: 'primary.main',
									margin: 'auto',
									maxWidth: '280px',
									'&:hover': {
										backgroundColor: 'primary.light',
									},
								}}
								variant='contained'
							>
								Save Changes
							</Button>
						</Tooltip>
					</Grid>
				</Grid>
			)}
		</Grid>
	) : null;
}
