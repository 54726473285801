import { MagnifyingGlassIcon } from '@mpx-sdk/images';
import { IconButton, Tooltip } from '@mui/material';
import { type ReactElement } from 'react';

interface SearchIconButtonProps {
	onClick?: () => void;
}

export default function SearchIconButton({ onClick }: SearchIconButtonProps): ReactElement {
	return (
		<Tooltip arrow describeChild title='Search'>
			<IconButton
				aria-label='Search'
				form='search'
				onClick={(e) => {
					e.stopPropagation();
					onClick?.();
				}}
				type='submit'
			>
				<MagnifyingGlassIcon />
			</IconButton>
		</Tooltip>
	);
}
