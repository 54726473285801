import { availableCreditsAtom, inAppBrowserAtom, userAtom } from '@mpx-sdk/shared/atoms';
import CreditsLeft from '@mpx-sdk/ui/components/purchase/CreditsLeft';
import { Skeleton, Tooltip } from '@mui/material';
import { useAtomValue } from 'jotai';
import { type ReactElement } from 'react';

/**
 *  Displays the number of slots used and credits available for the current user.
 */
export default function NavbarAccountSlots(): ReactElement | null {
	const availableCredits = useAtomValue(availableCreditsAtom);
	const currentUser = useAtomValue(userAtom);
	const inApp = useAtomValue(inAppBrowserAtom);

	if (!currentUser || inApp) {
		return null;
	}

	return availableCredits ? (
		<CreditsLeft openPurchaseDialog showText={false} />
	) : (
		<Tooltip arrow describeChild title='Credits loading...'>
			<Skeleton
				height={43}
				sx={{ borderRadius: '123.69px', padding: '2px 11px' }}
				variant='rounded'
				width={104}
			/>
		</Tooltip>
	);
}
