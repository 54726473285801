import Gallery from '@mpx-sdk/ui/components/profile/display-container/Gallery';
import { Stack } from '@mui/material';
import { type ReactElement } from 'react';

export default function UserDisplay(): ReactElement | null {
	return (
		<Stack
			direction='column'
			sx={{
				width: '100%',
			}}
		>
			<Gallery />

			{/* <Bookmarks /> */}
		</Stack>
	);
}
