'use client';

import { inAppBrowserAtom } from '@mpx-sdk/shared/atoms';
import { defaultSearchConfig, searchClient } from '@mpx-sdk/shared/configs/algolia';
import { SearchParamKeys } from '@mpx-sdk/types';
import ContentRefinement from '@mpx-sdk/ui/components/algolia/ContentRefinement';
import InfiniteHits from '@mpx-sdk/ui/components/algolia/InfiniteHits';
import NoResultsBoundary from '@mpx-sdk/ui/components/algolia/NoResultsBoundary';
import NoResults from '@mpx-sdk/ui/components/algolia/NoSearchResults';
import AlgoliaSearchBar from '@mpx-sdk/ui/components/search-bar/AlgoliaSearchBar';
import { useAtomValue } from 'jotai';
import { isArray } from 'lodash';
import { type ReactElement } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';

interface AlgoliaDisplayProps {
	/** Whether to display certain components of Algolia or not */
	display?: {
		/** Whether to display the search bar [true, default] or not [false] */
		searchBar?: boolean;
		/** Whether to display the content refinement [true, default] or not [false] */
		refinement?: boolean;
	};
	/** JSX component where the hits will be displayed in */
	assetDisplayJSX?: React.ReactElement;
	/** Custom search settings to override the default search settings */
	customSearchSettings?: any;
	/** The ID of the element to scroll to when the user clicks on a search result */
	infiniteScrollTarget?: string;
}

const defaultDisplay = {
	searchBar: true,
	refinement: true,
};

export default function AlgoliaDisplay({
	display = defaultDisplay,
	assetDisplayJSX,
	customSearchSettings = {},
	infiniteScrollTarget,
}: Readonly<AlgoliaDisplayProps>): ReactElement {
	const inApp = useAtomValue(inAppBrowserAtom);

	const searchSettings = {
		...defaultSearchConfig(inApp),
		...customSearchSettings,
	};

	display = {
		...defaultDisplay,
		...display,
	};

	return (
		<InstantSearch
			indexName={searchSettings.indexName}
			initialUiState={{
				projects: {
					refinementList: {
						category: searchSettings.category ?? [],
						facet: searchSettings.facet ?? [],
						facetFilters: searchSettings.facetFilters ?? [],
						tags: searchSettings.tags ?? [],
					},
					query: searchSettings.query ?? '',
				},
			}}
			insights
			routing={{
				stateMapping: {
					stateToRoute(uiState) {
						const { query, refinementList, sortBy } = uiState[searchSettings.indexName];

						const mappings = {
							[SearchParamKeys.Query]: query,
							[SearchParamKeys.Category]:
								refinementList?.category?.length === 4
									? null
									: refinementList?.category?.join?.(',') ?? undefined,
							[SearchParamKeys.Tags]: refinementList?.tags?.join?.(',') ?? undefined,
							[SearchParamKeys.Username]: refinementList?.['user.username'],
							[SearchParamKeys.Sorting]: sortBy,
						};

						return mappings;
					},
					routeToState(routeState) {
						const query = routeState[SearchParamKeys.Query];
						const category = routeState[SearchParamKeys.Category];
						const tags = routeState[SearchParamKeys.Tags];
						const username = routeState[SearchParamKeys.Username];
						const sorting = routeState[SearchParamKeys.Sorting];

						return {
							[searchSettings.indexName]: {
								query,
								refinementList: {
									category: isArray(category)
										? category
										: (category?.split(',') as string[]) || undefined,
									tags: isArray(tags) ? tags : (tags?.split(',') as string[]) || undefined,
									'user.username': username ?? undefined,
								},
								sortBy: sorting ?? undefined,
							},
						};
					},
				},
			}}
			searchClient={searchClient}
		>
			<Configure
				analytics
				enablePersonalization
				enableReRanking
				enableRules
				facetFilters={searchSettings.facetFilters ?? []}
				hitsPerPage={searchSettings.hitsPerPage}
				ruleContexts={searchSettings.ruleContexts ?? []}
			/>

			{display.searchBar && <AlgoliaSearchBar searchSettings={searchSettings} />}

			<NoResultsBoundary fallback={<NoResults />}>
				{display.refinement && <ContentRefinement searchSettings={searchSettings} />}

				<InfiniteHits assetDisplayJSX={assetDisplayJSX} infiniteScrollTarget={infiniteScrollTarget} />
			</NoResultsBoundary>
		</InstantSearch>
	);
}
