/* eslint-disable react/prop-types */
import { inAppBrowserAtom, libraryActiveCategories } from '@mpx-sdk/shared/atoms';
import SortBy from '@mpx-sdk/ui/components/algolia/SortBy';
import SearchTextInput from '@mpx-sdk/ui/components/search-bar/SearchTextInput';
import SearchClearButton from '@mpx-sdk/ui/components/search-bar/buttons/SearchClearButton';
import SearchIconButton from '@mpx-sdk/ui/components/search-bar/buttons/SearchIconButton';
import { Box, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { isEmpty, isEqual } from 'lodash';
import { useSearchParams } from 'next/navigation';
import { type ReactElement, useEffect, useState } from 'react';
import { useSearchBox } from 'react-instantsearch';

/**
 * Algolia searchbox designed just for the library, it includes multiple components
 * like searchbox, category filter, and more.o
 */
function AlgoliaSearchBar(props): ReactElement {
	const { query, refine, clear } = useSearchBox(props);
	const { searchSettings } = props;

	const searchParams = useSearchParams();
	const phaseDependentState = searchParams.get('f');
	const searchQuery = searchParams.get('q');

	const [placeholderText, setPlaceholderText] = useState('Search');

	const activeCategory = useAtomValue(libraryActiveCategories);

	const inApp = useAtomValue(inAppBrowserAtom);

	useEffect(() => {
		if (
			phaseDependentState &&
			activeCategory &&
			!isEqual(activeCategory.sort(), inApp ? ['model'] : ['animation', 'material', 'model', 'rig'])
		) {
			// If only one category, do "[0]", if more than one, add an "and" between the last two
			const humanReadableCategory =
				activeCategory.length > 1
					? `${activeCategory.slice(0, -1).join(', ')} and ${activeCategory.slice(-1)}`
					: activeCategory[0];

			setPlaceholderText(
				`Search ${
					humanReadableCategory.charAt(0).toUpperCase() + humanReadableCategory.slice(1).toLowerCase()
				}`,
			);
		} else if (searchQuery) {
			setPlaceholderText(searchQuery.toString());
		} else {
			setPlaceholderText('Search');
		}
	}, [activeCategory, inApp, phaseDependentState, searchQuery]);

	return (
		<Stack
			alignItems='center'
			direction='row'
			justifyContent='center'
			spacing={1}
			sx={{
				margin: inApp ? '1%' : 'initial',
				maxWidth: '100vw',
				paddingTop: '1%',
				width: '100%',
			}}
		>
			{inApp && searchSettings && (
				<Box
					sx={{
						maxWidth: '35%',
						height: '100%',
					}}
				>
					<SortBy
						defaultRefinement={searchSettings.sortByIndex}
						items={searchSettings.sortByOptions.map((item) => ({
							value: item.indexName,
							label: item.label,
						}))}
					/>
				</Box>
			)}

			<form
				id='search'
				onSubmit={(e) => {
					e.preventDefault();
					refine((e.target as any).search.value);
				}}
				style={{
					width: '100%',
				}}
			>
				<SearchTextInput
					InputProps={
						{
							endAdornment: (
								<>
									<SearchIconButton />

									{!isEmpty(query) && <SearchClearButton onClick={clear} />}
								</>
							),
						} as any
					}
					placeholder={placeholderText}
					query={query}
				/>
			</form>
		</Stack>
	);
}

export default AlgoliaSearchBar;
