import { sanitizeString } from '@mpx-sdk/helpers/input';
import SearchTextInput from '@mpx-sdk/ui/components/search-bar/SearchTextInput';
import SearchIconButton from '@mpx-sdk/ui/components/search-bar/buttons/SearchIconButton';
import { InputAdornment } from '@mui/material';
import { type ReactElement } from 'react';

interface SearchBarProps {
	/** Whether to display the search button [false, default] or not [true] */
	noSearchButton?: boolean;
	/** The function to call upon search action */
	onSearch?: (query: string) => void;
}

/**
 * Create a search bar with a search button
 * @returns {JSX} The search bar and search button
 * @example <caption>Call this component when you want to display a search bar on your app/page/component. You need to give a onSearch for it to do anything. If you do not want the search button to appear, also include noSearchButton={false}</caption>
 * <SearchBar onSearch={onSearch} noSearchButton={true} />
 * // returns {JSX component for search bar and search button}
 * @export
 */
export default function SearchBar({ noSearchButton, onSearch }: Readonly<SearchBarProps>): ReactElement {
	/** Handle when a key press happens */
	const handleKeyPress = (event, search = false) => {
		/** Key press type */
		const eventType = event?.key || event?.type;

		/** Current query if exists */
		let query = event?.target?.defaultValue || event?.target?.textContent || '';

		// Sanitize query
		query = sanitizeString(query) || '';

		// If press enter, do search function
		if (['enter'].includes(eventType.toLowerCase()) || search) {
			if (onSearch) {
				onSearch(query);
			}
		}
	};

	return (
		<SearchTextInput
			id='search-bar'
			InputProps={{
				startAdornment: (
					<InputAdornment
						position='start'
						sx={{
							marginRight: 'auto',
							button: {
								padding: 'min(0.5rem, 6px)',
							},
						}}
					>
						{!noSearchButton && (
							<SearchIconButton
								onClick={() => {
									const inputQuery: string =
										(document.getElementById('search-bar') as HTMLInputElement)?.value || '';

									handleKeyPress(
										{
											target: {
												defaultValue: inputQuery,
											},
											key: 'Enter',
										},
										true,
									);
								}}
							/>
						)}
					</InputAdornment>
				),
			}}
			onKeyDown={handleKeyPress}
		/>
	);
}
