import { getBalanceByCurrency } from '@mpx-sdk/helpers/economy';
import { DataLayer } from '@mpx-sdk/helpers/measurement';
import { CreditsIcon } from '@mpx-sdk/images';
import { availableCreditsAtom, userAtom, userHistoryAtom } from '@mpx-sdk/shared/atoms';
import { useDialogService } from '@mpx-sdk/ui/components/core/DialogService';
import BuyMoreCredit from '@mpx-sdk/ui/components/purchase/BuyMoreCredit';
import { IconButton, Tooltip } from '@mui/material';
import { useAtomValue } from 'jotai';
import { type ReactElement, useEffect, useState } from 'react';

interface CreditsLeftProps {
	/** Whether to show the text "credits" after the number of credits */
	showText?: boolean;
	/** Whether to open the purchase dialog when the user clicks on the credits */
	openPurchaseDialog?: boolean;
}

/** Displays the number of credits available to the current user. */
export default function CreditsLeft({
	showText = true,
	openPurchaseDialog = false,
}: Readonly<CreditsLeftProps>): ReactElement {
	const availableCredits = getBalanceByCurrency('credits', useAtomValue(availableCreditsAtom)) || 0;
	const currentUser = useAtomValue(userAtom);
	const userHistory = useAtomValue(userHistoryAtom);
	const { showDialog } = useDialogService();

	const [displayedCredits, setDisplayedCredits] = useState(availableCredits);

	useEffect(() => {
		if (!availableCredits) return;

		const increment = availableCredits > displayedCredits ? 1 : -1;
		const interval = setInterval(() => {
			if (displayedCredits !== availableCredits) {
				setDisplayedCredits((prevCredits) => prevCredits + increment);
			}
		}, 10); // Adjust the interval duration as needed for smoother animation

		return () => clearInterval(interval);
	}, [availableCredits, displayedCredits]);

	return (
		<Tooltip arrow describeChild title='Credits'>
			<IconButton
				aria-label='Buy more credits'
				onClick={() => {
					if (openPurchaseDialog) {
						showDialog(BuyMoreCredit.getAsDialog());

						DataLayer.triggerMeasurementEvent('buyEvent', {
							event_name: 'click_get_more_credits',
							user_id: currentUser?.id,
							user_number_of_created_models: userHistory?.length ?? 0,
						});
					}
				}}
				sx={{
					alignItems: 'center',
					border: '1px solid #444',
					borderRadius: '123.69px',
					cursor: openPurchaseDialog ? 'pointer' : 'default',
					display: 'flex',
					flexShrink: 0,
					fontSize: '1.125rem',
					fontStyle: 'normal',
					fontWeight: 600,
					gap: '12px',
					justifyContent: 'center',
					lineHeight: '38px',
					padding: '2px 11px',
					textAlign: 'center',
				}}
			>
				<CreditsIcon />
				&nbsp;
				{displayedCredits}
				{showText && <>&nbsp; credits</>}{' '}
			</IconButton>
		</Tooltip>
	);
}
