/* eslint-disable jsx-a11y/label-has-associated-control */
import { request } from '@mpx-sdk/helpers/api';
import { userAtom } from '@mpx-sdk/shared/atoms';
import Spinner from '@mpx-sdk/ui/components/core/OverlayLoading';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from '@mui/material';
import { useAtomValue } from 'jotai';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import { toast } from 'react-toastify';

export default function ProfileDeleteAccount() {
	const router = useRouter();
	const [accountModal, setAccountModal] = useState(false);
	const [displaySpinner, setDisplaySpinner] = useState(false);
	const currentUser = useAtomValue(userAtom);

	const initialValues = {};
	Object.assign(initialValues, currentUser);

	/**
	 * Handle account deletion warning modal toggle
	 */
	const handleAccountModalToggle = () => {
		setAccountModal(!accountModal);
	};

	/**
	 * Handle account deletion action
	 * @param {Object} field User's profile data
	 */
	const handleDeleteButton = async () => {
		setDisplaySpinner(true);

		// Remove modal as it will appear above loading indicator
		handleAccountModalToggle();

		// Try needed to ensure that the user deletion occurs, if not throw error
		try {
			// Request deletion of account
			await request(`/me`, 'DELETE');

			// Given UI prompt to user that account has been deleted
			toast.success('Your account has been deleted!');

			setTimeout(() => {
				router.push('/api/auth/logout', undefined, {
					shallow: true,
				});
			}, 1000);
		} catch (err) {
			toast.error('An error occurred in deleting your account. Please try again later');
			console.error(err);

			// Remove loading indicator as deleting account failed
			setDisplaySpinner(false);
			// Bring back delete account modal
			handleAccountModalToggle();
		}
	};

	return (
		<>
			<label>
				<Typography
					sx={{
						lineHeight: '78.5px',
						letterSpacing: '-0.56px',
						fontSize: '1.75rem',
					}}
				>
					Delete your account
				</Typography>
				<Button
					aria-label='Delete Account Button'
					id='delete-account-button'
					onClick={() => {
						handleAccountModalToggle();
					}}
					sx={{
						backgroundColor: '#666666',
						color: 'white',
						'&:hover': {
							color: 'primary.contrastText',
						},
					}}
					variant='contained'
				>
					Delete Account
				</Button>
			</label>{' '}
			{/* Delete account UI */}
			{/* Warning pop-up to ensure if user wants to delete account */}
			<Dialog
				aria-describedby='delete-account'
				aria-labelledby='delete-account'
				maxWidth='md'
				onClose={handleAccountModalToggle}
				open={accountModal}
				sx={{
					'.MuiDialog-paper': {
						backgroundColor: '#fff',
						color: '#000',
						borderRadius: '1rem',
					},
					background: 'linear-gradient(180deg, #19191D75 0%, #00352A 100%)',
				}}
			>
				<DialogTitle
					id='delete-account'
					sx={{
						fontSize: 'clamp(3rem, 3.5rem, 4rem)',
					}}
				>
					We&apos;re sorry to see you go 😔
				</DialogTitle>

				<DialogContent
					sx={{
						// change font color of text
						'.MuiDialogContentText-root': {
							color: '#000',
						},
					}}
				>
					<DialogContentText id='alert-dialog-description'>
						We&apos;re sorry to hear that you&apos;re considering deleting your account. Before proceeding,
						we want to ensure you have the best experience possible.
						<br />
						<br />
						If you&apos;re facing any issues, please contact{' '}
						<Link
							aria-label='Get help and feedback'
							href='/feedback'
							style={{
								textDecoration: 'underline',
							}}
						>
							{' '}
							our support team
						</Link>
						. We value your feedback, so if there&apos;s a specific reason for deletion, let us know.
						<br />
						<br />
						Thank you for being part of our community! 😊
						{/* Warning text */}
						<br />
						<br />
						<Typography color='error.main'>
							<b>Warning:</b> This action is irreversible. All of your data will be deleted and you will
							not be able to recover it. Please proceed with caution.
							<br />
							<br />
							Once you initiate the deletion process, you will be logged out of your account and
							redirected to the home page. You will receive an email confirming that your account has been
							deleted. Your account will be deleted within 7 days of the request. If you log back in
							within that time, your account will not be deleted but instead reactivated.
						</Typography>
					</DialogContentText>
				</DialogContent>

				<DialogActions className='mb-2'>
					<Button
						aria-label='Cancel deletion process'
						autoFocus
						className='e-2'
						color='primary'
						onClick={handleAccountModalToggle}
						variant='contained'
					>
						Nevermind
					</Button>
					<Button
						aria-label='Confirm deletion process'
						className='me-2'
						color='error'
						onClick={handleDeleteButton}
						variant='outlined'
					>
						Delete Account
					</Button>{' '}
				</DialogActions>
			</Dialog>
			{displaySpinner ? (
				<div
					style={{
						position: 'fixed',
						backgroundColor: 'rgb(128 128 128 / 50%)',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
					}}
				>
					<Spinner />
				</div>
			) : null}
		</>
	);
}
